<template>
  <KTPortlet headSize="md">
    <template v-slot:body>
      <b-form-group
        id="outlets-group"
        :label="hidenTitle ? '' : 'Chọn tiệm'"
        label-for="outlets"
      >
        <b-form-select
          id="outlets"
          :options="outlets"
          v-model="selectedOutlet"
          @change="onSelectOutlet"
        >
          <template v-slot:first v-if="outlets.length === 0">
            <b-form-select-option disabled
              >Không tìm thấy tiệm nào</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
    </template>
  </KTPortlet>
</template>

<style lang="scss" scoped>
#outlets-group {
  margin-bottom: 0 !important;
}
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { getActivedOutlet } from "@/api/user";

export default {
  name: "OutletSelect",
  props: {
    hidenTitle: Boolean
  },
  components: {
    KTPortlet
  },
  data() {
    return {
      selectedOutlet: "",
      outlets: []
    };
  },
  created() {
    getActivedOutlet().then(res => {
      this.outlets = res.data.map(o => {
        return {
          value: o._id,
          text: o.name
        };
      });
      this.selectedOutlet = this.outlets[0].value;
      this.onSelectOutlet();
    });
  },

  methods: {
    onSelectOutlet() {
      localStorage.setItem("outletId", this.selectedOutlet);
      this.$emit("change", this.selectedOutlet);
    }
  },
  computed: {}
};
</script>
