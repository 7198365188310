<template>
  <b-input-group class="mb-2 mr-sm-2 mb-sm-0 search-box">
    <template v-if="hasToolbarSlot">
      <slot name="toolbar">
        <template>
          <b-button variant="primary">Tạo bảng mới</b-button>
        </template>
      </slot>
    </template>
    <b-input-group-prepend>
      <span class="input-group-text">
        <i class="flaticon-search"></i>
      </span>
    </b-input-group-prepend>
    <b-input
      :placeholder="pHolder"
      v-on:keyup="onKeyUp"
      v-model="searchText"
    ></b-input>
    <template v-if="hasToolbarSlot">
      <slot name="toolbar">
        <template>
          <b-dropdown
            right
            no-caret
            id="dropdown-right"
            text="Kích hoạt"
            size="sm"
            class="btn-filter ml-3"
            variant="outline-secondary"
          >
            <template v-slot:button-content>
              <i class="flaticon2-console"></i>
            </template>
            <b-dropdown-item href="#" @click="filterStatus(StatusCLient.Paid)"
              >Đã thanh toán</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="filterStatus(StatusCLient.Doing)"
              >Đang phục vụ</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="filterStatus(StatusCLient.ReadyToPay)"
              >Sẵn sàng thanh toán</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </slot>
    </template>
  </b-input-group>
</template>

<script>
import _ from "lodash";
import { StatusCLient } from "./../constans";
export default {
  name: "SearchInput",
  data() {
    return {
      searchText: ""
    };
  },
  props: {
    pHolder: String
  },
  methods: {
    onKeyUp: _.debounce(function() {
      this.$emit("search", this.searchText);
    }, 300),

    filterStatus(status) {
      this.$emit("filterStatus", status);
    }
  },
  computed: {
    hasToolbarSlot() {
      return !!this.$slots["toolbar"];
    }
  }
};
</script>

<style></style>
