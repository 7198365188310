<template>
  <div>
    <KTPortlet
      class="set-height-product"
      title="Danh sách dịch vụ"
      headSize="md"
    >
      <template v-slot:toolbar>
        <router-link :to="{ name: 'product-create' }">
          <i class="flaticon-add mr-2"></i>
          <span>Tạo mới</span>
        </router-link>
      </template>
      <template v-slot:body>
        <div class="row">
          <div class="col-6">
            <SearchInput
              pHolder="Nhập để tìm kiếm..."
              @search="onSearch"
            ></SearchInput>
          </div>
          <div class="col-6 search-outlet">
            <OutletSelect
              @change="onSelectOutlet"
              :hidenTitle="true"
            ></OutletSelect>
          </div>
        </div>

        <div class="mt-4 table-product table-custom">
          <b-table
            responsive
            striped
            hover
            :items="products"
            :fields="fields"
            :sticky-header="true"
            head-variant="light"
            v-if="products.length"
          >
            <template v-slot:cell(status)="data">
              <b-dropdown
                right
                variant="success"
                no-caret
                id="dropdown-right"
                text="Kích hoạt"
                v-if="data.value === 1"
                size="sm"
                class="btn-status"
              >
                <b-dropdown-item
                  href="#"
                  @click="onChangeActived(data.item, status.Actived)"
                  >Kích hoạt</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="onChangeActived(data.item, status.NotActived)"
                  >Chưa KH</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                right
                variant="danger"
                no-caret
                id="dropdown-right"
                text="Chưa KH"
                size="sm"
                v-else-if="data.value === 2"
                class="btn-status"
              >
                <b-dropdown-item
                  href="#"
                  @click="onChangeActived(data.item, status.Actived)"
                  >Kích hoạt</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="onChangeActived(data.item, status.NotActived)"
                  >Chưa KH</b-dropdown-item
                >
              </b-dropdown>
            </template>
            <template v-slot:cell(id)="data">
              <div class="text-right edit-product">
                <b-dropdown
                  right
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="b-none"
                >
                  <template v-slot:button-content>
                    <i class="flaticon-more"></i>
                  </template>
                  <b-dropdown-item href="#">
                    <router-link
                      :to="{
                        name: 'product-edit',
                        params: { item: data.item }
                      }"
                    >
                      <div class="d-flex align-items-center">
                        <i class="flaticon-edit-1 mr-2"></i>
                        <span>Chỉnh sửa</span>
                      </div>
                    </router-link>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <div v-else class="d-flex justify-content-center">
            <section v-if="loading" class="loading align-items-center">
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
            </section>
            <div class="loading align-items-center" v-else>
              <div class="text-center">
                <h1><i class="flaticon2-open-box"></i></h1>
                <h5>Không tìm thấy dữ liệu thích hợp</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 d-flex justify-content-center"
          v-if="totalItem > itemPage"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItem"
            :per-page="itemPage"
            id="promo-table"
          ></b-pagination>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<style lang="scss">
@import "./Product.scss";
.loading {
  height: calc(100vh - 505px);
}
</style>
<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import SearchInput from "@/components/SearchInput.vue";
import { Status } from "./../../constans";
import Loader from "@/common/loader";
import {getProducts} from "@/api/product";

export default {
  name: "List",
  components: {
    KTPortlet,
    OutletSelect,
    SearchInput
  },
  data() {
    return {
      outletId: "",
      status: Status,
      currentPage: 1,
      totalItem: 0,
      itemPage: 10,
      loading: false,
      products: [],
      searchText: ""
    };
  },
  computed: mapGetters(["allProducts"]),
  mounted() {
    this.$store.dispatch("titleBreadcrumb", "Quản lý dịch vụ");
  },

  watch: {
    currentPage(val) {
      this.getListProduct();
    }
  },

  methods: {
    getListProduct() {
      this.loading = true;
      this.products = [];
      return getProducts({
        outletId: this.outletId,
        text: this.searchText,
        page: this.currentPage
      })
      .then(({data}) => {
        this.products = data.products;
        this.totalItem = data.total;
      })
      .finally(() => this.loading = false);
    },
    onSelectOutlet(event) {
      this.outletId = event;
      this.getListProduct();
    },
    onChangeActived(data, status) {
      Loader.fire();
      this.$store
        .dispatch("updateProduct", {
          id: data._id,
          name: data.name,
          price: data.price,
          position: data.position,
          outletId: [this.outletId],
          status
        })
        .finally(() => {
          this.getListProduct()
          Loader.close()
        });
    },
    onSearch(event) {
      Loader.fire();
      this.searchText = event;
      this.getListProduct();
    }
  },
  computed: {
    fields() { return [
            {
              key: "name",
              label: "Tên"
            },
            {
              key: "status",
              label: "Trạng thái"
            },
            {
              key: "price",
              label: "Giá"
            },
            {
              key: "id",
              label: ""
            }
          ]}
  }
};
</script>
